/*
  Name: YEMABU
  Website: yemabu.com
  Developed By: W8 Agency, https://www.w8-agency.com
*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-inter;
  }
}

@layer components {
  .sticky-navbar {
    @apply !fixed !border-b !bg-opacity-80 !backdrop-blur-sm dark:!bg-opacity-80;
  }

  .sticky-navbar .menu-scroll.active,
  .menu-scroll.active {
    @apply text-primary dark:text-primary;
  }

  .sticky-navbar ul > .menu-item > a {
    @apply lg:py-[21px];
  }

  .submenu-item a.active {
    @apply text-primary dark:text-white;
  }
  .submenu-item a.active span svg {
    @apply rotate-180 lg:rotate-0;
  }

  .container {
    @apply w-full sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px];
  }

  .keep-signed:checked ~ span.box {
    @apply border-primary bg-primary;
  }

  .keep-signed:checked ~ span.box .icon {
    @apply block;
  }

  #togglePlan:checked ~ span .dot {
    @apply translate-x-[26px];
  }

  .faq .faq-btn::after {
    content: "+";
    @apply text-lg font-semibold text-black dark:text-white;
  }
  .faq.active .faq-btn::after {
    content: "-";
  }

  .faq .faq-content {
    @apply max-h-0 py-0 opacity-0 transition-all duration-100 ease-in-out;
  }

  .faq.active .faq-content {
    @apply max-h-fit py-[30px]  opacity-100;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply static top-auto bottom-0 flex h-10 w-10 items-center justify-center rounded-full border border-body text-body after:hidden hover:border-primary hover:bg-primary hover:text-white;
  }
}

.hero-download-icon-cta {
  display: block;
}

.hero-download-icon-ios {
  display: none;
}

.hero-download-icon-android {
  display: none;
}
